import styles from "./button.module.scss";

export default function Button({
  color,
  backgroundColor,
  label,
  borderColor,
  height,
  width,
  fontSize,
  size,
}) {
  let buttonClass = `${styles.buttonWrap}`;
  if (size === "large") {
    buttonClass += ` ${styles.large}`;
  }
  if (size === "xlarge") {
    buttonClass += ` ${styles.xlarge}`;
  }
  if (size === "medium") {
    buttonClass += ` ${styles.medium}`;
  }

  const buttonStyle = {
    color: color,
    backgroundColor: backgroundColor,
    borderColor: borderColor,
    height: height,
    width: width,
    fontSize: fontSize,
  };

  return (
    <div className={buttonClass}>
      <button style={buttonStyle}>{label}</button>
    </div>
  );
}
